<template>
    <div class="">
      <!-- v-if="getNavbarData.userType == 'shopify'" -->
       <Button btnVariant="yellow"  @click="open()">
        import from WooCommerce
      </Button>
      <!-- Shopify Modal -->
      <ModalVue
        ref="WooModal"
        id="WooModal"
        title="Add Products from Woo Commerce"
        size="lg">
        <div class="mx-2">
          <div class="my-2" :class="!isLoadingSync ? 'd-none' : 'd-block'">
            <ProgressbarVue ref="progressbar"></ProgressbarVue>
            <span
              class="text-success text-center"
              style="font-size: 12px; text-align: center">
              Products syncing soon, continue tasks uninterrupted.
            </span>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="d-flex w-100 align-items-center h-100">
                <FilterBox
              style="flex:1"
              :search="true"
              placeholder="Search Products..."
               v-model="filter.search"></FilterBox>
                
              </div>
            </div>
           
            </div>
            <div class="row">
            <div class="col-12 mt-2">
              <div class="d-flex flex-wrap align-items-center" style="gap:6px">
                <FilterSelect ref="Status" :isDynamic="false" type="radio"  filterFor="Status"  @update="(e)=>{filter.status=e.value?e.value:''}"  :static-options="filters.status" ></FilterSelect>
            <FilterDropdown filterFor="Price" @update="(e)=>{filter.max_price=e[1].value;filter.min_price=e[0].value}" :input="[{type:'number',value:'',label:'Minimum Price'},{type:'number',value:'',label:'Maximum Price'}]" ></FilterDropdown>
            <FilterDropdown filterFor="SKU" @update="(e)=>{filter.sku=e[0].value}" :input="[{type:'number',value:'',label:'SKU'}]" ></FilterDropdown>
            <FilterSelect ref="inventory" :isDynamic="false" type="radio"  filterFor="Inventory" @update="(e)=>{filter.stock_status=e.value?e.value:''}" :static-options="filters.inventory" ></FilterSelect>
            </div>
              
            </div>
            <div class="col-12">
              
              
              <div
                class="d-flex align-items-center"
                v-if="isLoadingCommerceStoreProducts">
                <b-spinner small style="color: #4d1b7e"></b-spinner>
                <span class="ml-2">Loading Products</span>
              </div>
             
         
          </div>
          </div>
  
          <b-skeleton-table
            v-if="isLoadingCommerceStoreProductFirstTime"
            :rows="5"
            :columns="3"
            
            :table-props="{ bordered: true, striped: true }"></b-skeleton-table>
          <div class="data-table" v-else-if="wooCommerceStoreProducts.length">
            <div
              class=""
              style="max-height: 40vh; min-height: 40vh; overflow: auto">
              
              <b-table
                 @row-clicked="RowClick"
                head-class="text-center"
                class="leadTable"
                :stickyColumn="true"
                hover
                :fields="wooCommerceProductsFields"
                :items="wooCommerceStoreProducts">
                <template #head(checkbox)>
                  <b-form-checkbox
                    size="lg"
                    :disabled="
                      isLoadingCommerceStoreProducts ||
                      isLoadingSelectedCommerceStoreProducts ||
                      isLoadingSync
                    "
                    v-model="wooCommerceSelectAllCheck"
                    @change="shopifyProductSelectAllToggle($event)"
                    class="checkbox selectAll"></b-form-checkbox>
                </template>
                <template #cell(checkbox)="data">
                  <b-form-checkbox
                    :value="data.item"
                    v-model="wooCommerceSelectedProducts"
                    :disabled="
                      isLoadingCommerceStoreProducts ||
                      isLoadingSelectedCommerceStoreProducts ||
                      isLoadingSync
                    "
                    size="lg"
                    class="checkbox"></b-form-checkbox>
                </template>
                <template #cell(title)="data">
                  <lazy-image
                    class="circle-image mr-2"
                    :src="
                      data.item.image
                        ? data.item.image
                        : 'https://images.quizell.com/website/default_start_page.png'
                    "
                    :key="data.item.image"></lazy-image>
                  {{ data.item.title }}
                </template>
              </b-table>
            </div>
          </div>
          <template v-else>

<div class="d-flex justify-content-center align-items-center" style="height: 50vh;" >

<EmptyTable >
<template #title>
No product found
</template>
<template #description>
Time to fill your shelves or refine your search. Remember, your results will appear right here.
</template>
</EmptyTable>
</div>

</template>
          <div class="d-flex justify-content-between align-items-center">
            <ShopifyPagination
              :hasNext="
                !(
                  isLoadingCommerceStoreProducts ||
                  isLoadingSelectedCommerceStoreProducts ||
                  isLoadingSync ||
                  disableNext
                )
              "
              @onNext="NextPageCLick()"
              :hasPrevious="
                !(
                  isLoadingCommerceStoreProducts ||
                  isLoadingSelectedCommerceStoreProducts ||
                  isLoadingSync ||
                  page == 1
                )
              "
              @onPrevious="PrevPageCLick()"></ShopifyPagination>
              <Button
                 
                  @click="syncSelected()"
                  :disabled="
                    isLoadingSync ||
                    isLoadingCommerceStoreProducts ||
                    isLoadingSelectedCommerceStoreProducts
                  "
                  :isLoading="isLoadingSelectedCommerceStoreProducts"
                  style="border-radius: 4px !important"
                  v-if="wooCommerceSelectedProducts.length"
                  >Sync Selected</Button
                >
                <Button
                  btnVariant="yellow"
                  @click="()=>{selectAllFromStore=true;syncSelected()}"
                  :disabled="
                    isLoadingSync ||
                    isLoadingCommerceStoreProducts ||
                    isLoadingSelectedCommerceStoreProducts
                  "
                  :isLoading="isLoadingSelectedCommerceStoreProducts"
                  style="border-radius: 4px !important"
                  v-else
                  >Sync All</Button
                >
          </div>
        </div>
      </ModalVue>
    </div>
  </template>
  <script>
  import EmptyTable from "../../Layout/EmptyTable.vue";
    import { mapGetters } from "vuex";
    import {debounce} from "lodash";
    import FilterDropdown from '../../Layout/FilterDropdown.vue';
    import FilterBox from '../../Layout/FilterBox.vue'

    import FilterSelect from '../../Layout/FilterSelect.vue';
    import ShopifyPagination from "../../Layout/ShopifyPagination.vue";
    import ModalVue from "../../Layout/Modal.vue";
    import ProgressbarVue from "../../Layout/Progressbar.vue";
    import Button from '../../Layout/Button.vue';
    
    export default {
      components: {
        ShopifyPagination,
        ModalVue,
        Button,
        EmptyTable,
        ProgressbarVue,
        FilterDropdown,
        FilterSelect,
        FilterBox
      },
      data() {
        return {
          filters:{
            status:[
          {
              title:'Publish',
              value:'publish'
            },
          {
              title:'Draft',
              value:'draft'
            },
          {
              title:'Pending',
              value:'pending'
            },
          {
              title:'Private',
              value:'private'
            },
          
          ],
          inventory:[{
            title:'In stock',
            value:'instock'
          },
        {
            title:'Out of stock',
            value:'outofstock'
          },
       
        {
            title:'On back stock',
            value:'onbackorder'
          }]
          },
        page: 1,
        per_page: 50,
        disableNext:false,
          selectAllFromStore:false,
          isLoadingCommerceStoreProducts: false,
          isLoadingCommerceStoreProductFirstTime: false,
          wooCommerceSelectAllCheck: false,
          wooCommerceSelectedProducts: [],
          wooCommerceStoreProducts: [],
          wooCommerceProductsFields: [
            {
              key: "checkbox",
              label: "",
              tdClass: "align-middle data-Table cursor-pointer",
              thClass: "bg-table-heading",
            },
            {
              key: "title",
              label: "Product",
              tdClass: "align-middle data-Table cursor-pointer",
              thClass: "bg-table-heading",
            },
            {
              key: "price",
              label: "Price",
              tdClass: "align-middle data-Table cursor-pointer",
              thClass: "bg-table-heading",
            },
            
          ],
          items: [],
          isLoadingSelectedCommerceStoreProducts: false,
          isLoadingDeleteProduct: false,
          isLoadingSync: false,
          isLoading: false,
          productProducts:[],
          filter:{
            
    "search": "",
    "status": "publish", //draft, pending, private and publish.Default is publish.
      "stock_status": "instock",//instock, outofstock, onbackorder. Default is instock.
    "sku": "",
    "max_price": "",
    "min_price": "",
          },
        };
      },
      watch: {

        filter:{
          handler(){
            this.handleSearchDebounced();
          },
          deep:true
        },
      checkAllProductsAreSelected(){
  
      },
        allProducts:{
          handler({selected,all}){
           this.wooCommerceSelectAllCheck=all.every(product=>selected.includes(product));
  
          },
          deep:true
        },
  
      },
      computed: {
        filteredObject(){
          const filter = {...this.filter}
          for (const key in filter) {
    if (Object.hasOwnProperty.call(filter, key) && filter[key] === "") {
      delete filter[key];
    }
  }
  return filter
        },
        zIndex(){
          return this.wooCommerceSelectedProducts.length>0?'1':'-1';
        },
  
        ...mapGetters(["getNavbarData"]),
        allProducts(){
          return {selected:this.wooCommerceSelectedProducts,all:this.wooCommerceStoreProducts}
        },
            getSaveWixObject(){
          const object={
            filter:{},
            dynamic:false,
            only_current_page_products:false,
            products:[],
          }
  
          if(this.selectAllFromStore)
          {
           object.filter={per_page:this.per_page, page:this.page,  ...this.filteredObject}
  
          }
          // if user select all products that are in table
         else if(this.wooCommerceAllProducts.length==this.wooCommerceSelectedProducts.length)
          {
            object.filter={per_page:this.per_page, page:this.page, ...this.filteredObject}
  
            object.only_current_page_products=true
          }
          // if user select random products from table
         else
          {
           object.dynamic=true;
          object.products=this.wooCommerceSelectedProducts
          }
  
          return object
        },
        wooCommerceAllProducts() {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          return this.wooCommerceStoreProducts
        },
      },
      methods: {
      RowClick(e){
    
       let index = this.wooCommerceSelectedProducts.findIndex(product=>product==e)
      if(index>-1)
      {
        this.wooCommerceSelectedProducts.splice(index,1)
        return
      }
      this.wooCommerceSelectedProducts.push(e)
      },
        resetFilter(){
          this.page=1
          this.filter={
            
    "search": "",
    "status": "publish", //draft, pending, private and publish.Default is publish.
      "stock_status": "instock",//instock, outofstock, onbackorder. Default is instock.
    "sku": "",
    "max_price": "",
    "min_price": "",
          },
          this.getProductsFromCommerceStoreStore();
        },
       NextPageCLick() {
        this.page += 5;
        this.getProductsFromCommerceStoreStore();
      },
       PrevPageCLick() {
        if (this.page > 0) {
          this.page -= 5;
          this.getProductsFromCommerceStoreStore();
        }},
        open() {
          this.resetFilter();
          this.isLoadingCommerceStoreProductFirstTime = true;
          this.$refs.WooModal.open();
         
        },
        async syncSelected() {
          this.isLoadingSelectedCommerceStoreProducts = true;
          const response = await this.$store.dispatch('fetchData',{
            axiosMethod:'post',
            resource:'wooCommerceProducts',
            method:'saveWooCommerceProducts',
            params:this.getSaveWixObject
          });
          if(response&&response.status)
          {
                this.$refs.WooModal.close();
              // this.getProducts("/product/list");
              this.$emit('updateProducts')
              this.$toasted.show("Products sync successfully", {
                theme: "toasted-primary",
                position: "bottom-center",
                duration: 2000,
              });
          }
          else {
            
   this.$toasted.show("Error occurred while syncing products", {
                theme: "toasted-primary",
                position: "bottom-center",
                duration: 2000,
              });
          }
  
          this.isLoadingSelectedCommerceStoreProducts = false;
        },
  
        shopifyProductSelectAllToggle() {
          this.wooCommerceSelectedProducts = [];
          if (this.wooCommerceSelectAllCheck) {
            this.wooCommerceSelectedProducts = this.wooCommerceAllProducts;
          }
          else this.selectAllFromStore=false
        },
    
        async getProductsFromCommerceStoreStore() {
          this.isLoadingCommerceStoreProducts = true;
        const response=await this.$store.dispatch('fetchData',{
          axiosMethod:'post',
          resource:'wooCommerceProducts',
          method:'getFromStore',
          params:{per_page:this.per_page,page:this.page,...this.filteredObject}
         })
         if(response && response.status=='success')
         {
            this.wooCommerceSelectedProducts=[];
            this.selectAllFromStore=false;
            this.wooCommerceStoreProducts=response.data
            if(this.wooCommerceStoreProducts.length < this.per_page){
              this.disableNext = true
            }else{
              this.disableNext = false
            }
         }
         else{
          this.$toasted.show("something went wrong", {
                theme: "toasted-primary",
                position: "bottom-center",
                duration: 2000,
              });
         }
          this.isLoadingCommerceStoreProducts = false;
          this.isLoadingCommerceStoreProductFirstTime = false;
        },
        handleSearchDebounced: debounce(function () {
          this.getProductsFromCommerceStoreStore();
        }, 500), // 500ms debounce time, adjust as needed
  
      },
    };
  </script>
  <style scoped>
  .shopify-btn:active {
    background-color: #002e25;
    color: #ffffff;
    border-color: #002e25;
  }
  .shopify-btn {
    appearance: none;
    cursor: pointer;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);
    background-color: #000;
    color: #ffffff;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
  
    font-size: 14px;
  
    transition: 150ms ease;
    transition-property: background-color, border-color;
    display: inline-block;
  
    border-radius: 6px;
  }
  .checkbox >>> .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: var(--secondary-color);
    background-color: var(--secondary-color) !important;
  }
  .checkbox >>> .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem var(--secondary-color);
  }
  .leadTable >>> thead {
    border-radius: 6px;
    color: black;
    background: rgba(179, 175, 182, 0.07);
    border: none;
  }
  .leadTable >>> thead tr {
    border-radius: 10px;
  }
  .leadTable >>> thead th {
    border: none;
    vertical-align: middle !important;
  }
  .loader {
    min-height: 40px;
  }
  .product-image {
    height: 40px;
    width: 40px;
    /* border: 4px solid rgb(255, 255, 255); */
    border-radius: 50%;
    -o-object-fit: cover;
    object-fit: cover;
    background-size: cover;
    /* padding: 0.25rem; */
    /* background-color: #fff; */
  }
  .customPagination >>> li > button {
    color: #4d1b7e;
  }
  
  .customPagination >>> li.active > button {
    color: white;
    background-color: #4d1b7e !important;
    border-color: #4d1b7e;
  }
  .customPagination >>> li > button:hover {
    color: white;
    background-color: #6b4690 !important;
    border-color: #4d1b7e;
  }
  #product-detail >>> button {
    background: transparent;
    border: none;
    margin: 0;
    padding: 0;
  }
  .search-text:focus-visible {
    outline: none;
  }
  .search-text:active + .fa-search {
    color: #4d1b7e;
  }
  .tag {
    background: #bfbfc0;
    border-radius: 20px;
    border: 1px dashed;
    font-size: 14px;
  }
  .icon {
    color: #949495;
    transition: 0.5s ease all;
  }
  .icon:hover {
    background: rgb(77 27 126 / 20%);
    color: #4d1b7e;
  }
  .filter-active {
    background: rgb(77 27 126 / 20%);
    color: #4d1b7e;
  }
  .search {
    border-radius: 6px;
    border: 0.8px solid #b3afb6;
  }
  .search:focus-within {
    color: #495057;
    background-color: #fff;
    /* border-color: #4d1b7e; */
    outline: none;
    box-shadow: 0 0 0 0.1rem rgba(77, 27, 126, 0.25);
  }
  .cursor-pointer {
    cursor: pointer;
  }
  #product-detail >>> button::after {
    display: none;
  }
  #product-detail >>> ul {
    background: #ffffff;
    box-shadow: 0px 10px 20px -3px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
  }
  #product-detail >>> li.topbarLink {
    color: #344054;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 5px;
  }
  #product-detail >>> li a:active {
    color: #fff;
    text-decoration: none;
    background-color: #4d1b7e;
  }
  /* .filter-controls::after{
      content:'';
      display: block;
      width: 100%;
      height: 1px;
      background-color: #edebeb;
    } */
  .sync-filtered:hover {
    color: #4d1b7e !important;
  }
  .product-stripe {
    position: relative;
    top: 38px;
    background: #f4f3f4;
    display: flex;
    align-items: center;
    top: 48px;
  }
  .product-stripe strong {
    color: #4d1b7e;
    cursor: pointer;
  }
  .product-stripe strong:hover {
    text-decoration: underline;
  }
  </style>
  